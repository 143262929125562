import type { ConnectProps, ConnectState } from '@/models/connect';
import { NoResults } from '@/sdk/pages/NoResults';
import { GAEvent } from '@/utils/utils';
import { getLocationParams, Menu } from '@propify-tenant-client/common';
import type { ActivePlace, Enum, Unit } from '@propify-tenant-client/services';
import {
  getSdkUnitListClassName,
  PointInMap,
  Toolbar,
  UnitList,
  UnitsMap,
} from '@propify-tenant-client/units';
import classNames from 'classnames';
import type { CSSProperties } from 'react';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Theme, WithStyles } from '../../utils/material';
import {
  Box,
  Card,
  CircularProgress,
  createStyles,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
  withTheme,
} from '../../utils/material';

type SortDirection = 'ASC' | 'DES';
type Sort = { key: string; direction: SortDirection };

const MAX_UNITS_IN_LIST = 100;

const styles = (theme: Theme) =>
  createStyles({
    main: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        overflow: 'auto',
      },
    },
    mapAndList: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.up('md')]: {
        overflow: 'auto',
      },
    },
    list: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100vw',
      [theme.breakpoints.up('md')]: {
        minWidth: 700,
        maxWidth: 700,
        overflow: 'auto',
      },
    },
    map: {
      flex: 1,
    },
    image: {
      height: '100px',
      width: '100%',
      textAlign: 'center',
      margin: 'auto',
    },
    header: {
      position: 'sticky',
      top: 0,
      margin: '0 0.5em',
      zIndex: 10,
    },
    headerTitle: {
      display: 'flex',
      margin: '0.5em',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 10,
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    headerSubtitle: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0.5em',
      },
    },
  });

type Props = WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  ConnectProps & {
    theme: Theme;
    showMap: boolean;
  };

type State = {
  sortBy: Sort;
};

const defaultSort: Sort = { key: 'listedRent', direction: 'ASC' };

class App extends PureComponent<Props, State> {
  public override state: State = {
    sortBy: defaultSort,
  };

  public override componentDidMount() {
    this.handleSortBy({ sort: defaultSort });
  }

  private getPoints = () => {
    const { units } = this.props;

    return units
      .filter((unit: Unit) => !!unit.property.address.location)
      .map((unit: Unit, key: number) => ({
        id: unit.id,
        latitude: unit.property.address.location?.latitude,
        longitude: unit.property.address.location?.longitude,
        key,
        render: () => <PointInMap unit={unit} />,
        to: `/units/${unit.id}`,
      }));
  };

  private handleSortBy = ({ sort }: any) => {
    this.setState({ sortBy: sort });

    this.props.dispatch({
      type: 'sdk/sortBy',
      payload: sort,
    });
  };

  private sortOptions = () => [
    {
      value: 'displayRent-ASC',
      sort: { key: 'listedRent', direction: 'ASC' },
      label: 'Rent (Low to High)',
    },
    {
      value: 'displayRent-DES',
      sort: { key: 'listedRent', direction: 'DES' },
      label: 'Rent (High to Low)',
    },
    {
      value: 'bedroomCount-DES',
      sort: { key: 'bedroomCount', direction: 'DES' },
      label: 'Bedrooms',
    },
    {
      value: 'bathroomCount-DES',
      sort: { key: 'bathroomCount', direction: 'DES' },
      label: 'Bathrooms',
    },
    {
      value: 'squareFeet-DES',
      sort: { key: 'squareFeet', direction: 'DES' },
      label: 'Square Feet',
    },
  ];

  private fetchUnits = () => {
    this.props.dispatch({ type: 'sdk/fetchUnits', payload: this.props.config.unitParameters });
  };

  private sdkRedirect = () => {
    this.props.dispatch({ type: 'sdk/redirect' });
  };

  private setFilter = (newFilter: Record<string, any>) => {
    this.props.dispatch({
      type: 'sdk/filter',
      payload: newFilter,
    });
  };

  private setStateEnums = (stateEnums: Enum[]) => {
    this.props.dispatch({ type: 'sdk/setStateEnums', payload: stateEnums });
  };

  private onRegisterSubmit = () => {
    GAEvent('User', 'Register');
  };

  private onLoginSubmit = () => {
    GAEvent('User', 'Login');
  };

  private onScheduleShowingStart = () => {
    GAEvent('User', 'Schedule showing');
  };

  private setActivePlace = (activePlace?: Partial<ActivePlace>) => {
    this.props.dispatch({
      type: 'sdk/setActivePlace',
      payload: activePlace,
    });
  };

  private updateMapData = (data: Record<string, any>) => {
    this.props.dispatch({
      type: 'sdk/updateMapData',
      payload: data,
    });
  };

  public override render() {
    const {
      classes,
      config,
      units = [],
      visibleUnits,
      blankPage,
      activePlace,
      loading,
      showMap,
    } = this.props;
    const { sortBy } = this.state;

    const toolbarParams = getLocationParams();

    const mapProps = {
      points: this.getPoints(),
      activePlace,
    };

    const mainStyle: CSSProperties = {};
    const mapStyle: CSSProperties = {};
    if (config.shadowRoot && !config.onlySearchBar) {
      mainStyle.height = config.shadowMainHeight;
      mapStyle.height = '100%';
      mapStyle.width = '100%';
    }

    const hasResults = !!units.length;
    const shown = Math.min(MAX_UNITS_IN_LIST, visibleUnits.length);
    const total = units.length;
    const sortOptions = this.sortOptions();
    const sortValue =
      sortOptions.find(({ sort }) => sort.key === sortBy.key && sort.direction === sortBy.direction)
        ?.value ?? '';

    return (
      <div
        className={classNames(classes.main, getSdkUnitListClassName('container'))}
        style={mainStyle}
      >
        <Toolbar
          params={toolbarParams}
          fetchUnits={this.fetchUnits}
          filter={this.props.filter}
          sdkRedirect={this.sdkRedirect}
          setFilter={this.setFilter}
          setStateEnums={this.setStateEnums}
          stateEnums={this.props.stateEnums}
          states={this.props.states}
          onlySearchBar={this.props.config.onlySearchBar}
          redirectSearchTo={this.props.config.redirectSearchTo}
        />
        {loading && (
          <Box
            p={8}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
            <Box mt={1}>
              <Typography variant="caption">Loading properties...</Typography>
            </Box>
          </Box>
        )}
        {!config.onlySearchBar && hasResults && (
          <div className={classNames(classes.mapAndList, getSdkUnitListClassName('map-and-list'))}>
            <div className={classNames(classes.list, getSdkUnitListClassName('list'))}>
              {!!shown && !!total && (
                <div className={classNames(classes.header, getSdkUnitListClassName('header'))}>
                  <Card>
                    <div
                      className={classNames(
                        classes.headerTitle,
                        getSdkUnitListClassName('headerTitle'),
                      )}
                    >
                      <div
                        className={classNames(
                          classes.headerSubtitle,
                          getSdkUnitListClassName('headerSubtitle'),
                        )}
                      >
                        <Typography component="span">
                          Viewing {shown} homes out of {total}
                        </Typography>
                      </div>
                      <div>
                        <Menu
                          title="Sort By"
                          value={sortValue}
                          onChange={this.handleSortBy}
                          options={sortOptions}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              )}

              <UnitList
                selectionEnabled={showMap}
                maxDisplay={MAX_UNITS_IN_LIST}
                login={this.props.login}
                onLoginSubmit={this.onLoginSubmit}
                onRegisterSubmit={this.onRegisterSubmit}
                onScheduleShowingStart={this.onScheduleShowingStart}
                setActivePlace={this.setActivePlace}
                units={visibleUnits}
                activePlace={this.props.activePlace}
                trackingMap={this.props.config?.trackingMap}
              />
            </div>

            {showMap && (
              <div
                className={classNames(classes.map, getSdkUnitListClassName('map'))}
                style={mapStyle}
              >
                <UnitsMap
                  {...mapProps}
                  setActivePlace={this.setActivePlace}
                  mapData={this.props.mapData}
                  updateMapData={this.updateMapData}
                />
              </div>
            )}
          </div>
        )}
        {!config.onlySearchBar && !hasResults && !loading && !blankPage && <NoResults />}
      </div>
    );
  }
}

const mapStateToProps = (state: ConnectState) => ({
  config: state.sdk.config,
  blankPage: state.sdk.blankPage,
  units: state.sdk.filteredUnits,
  visibleUnits: state.sdk.visibleUnits,
  activePlace: state.sdk.activePlace,
  loading: state.loading.effects['sdk/fetchUnits'],
  filter: state.sdk.filter,
  stateEnums: state.sdk.stateEnums,
  states: state.sdk.states,
  login: state.account?.login,
  mapData: state.sdk.mapData,
});

const withShowMapProp = (Component: any) => (props: any) => {
  const theme = useTheme();
  const showMap = useMediaQuery(theme.breakpoints.up('md'));
  return <Component showMap={showMap} {...props} />;
};

export default withShowMapProp(
  withTheme(withStyles(styles, { name: 'App' })(connect(mapStateToProps)(App))),
);
