import type { ConnectState, Effect, Model, Reducer } from '@/models/connect';
import { collator } from '@/utils/utils';
import { getBathroomCount, getHistory } from '@propify-tenant-client/common';
import type { ActivePlace, Enum, PlaceType, Unit } from '@propify-tenant-client/services';
import { ListingStatus, UnitService } from '@propify-tenant-client/services';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import type ReactGA from 'react-ga';
import type { ThemeOptions } from '../utils/material';

export type SdkConfig = {
  shadowRoot?: boolean;
  elementSelector?: string;
  onlySearchBar?: boolean;
  debug?: boolean;
  theme?: ThemeOptions;
  background?: string;
  redirectSearchTo?: string;
  searchOnMount?: boolean;
  tracking?: string;
  trackingMap?: Record<string, any>;
  token?: string;
  trackers?: ReactGA.Tracker[];
  shadowMainHeight?: string;
  unitParameters?: {
    communityId?: number[];
  };
};

export type SearchOption = {
  type: PlaceType;
  displayValue: string;
  value: string;
  keyword: string;
};

export type SdkModelState = {
  config: Partial<SdkConfig>;
  units: Unit[];
  visibleUnits: Unit[];
  blankPage: boolean;
  activePlace?: ActivePlace;
  mapData: Record<string, any>;
  stateEnums: Enum[];
  states: Enum[];
  filter: Record<string, any>;
  filteredUnits: Unit[];
};

type Effects = {
  fetchUnits: Effect;
  updateMapData: Effect;
  redirect: Effect;
  filter: Effect;
  search: Effect;
};

type Reducers = {
  setUnits: Reducer<SdkModelState>;
  sortBy: Reducer<SdkModelState>;
  setActivePlace: Reducer<SdkModelState>;
  setConfig: Reducer<SdkModelState>;
  setVisibleUnits: Reducer<SdkModelState>;
  setMapData: Reducer<SdkModelState>;
  setStateEnums: Reducer<SdkModelState>;
  setStates: Reducer<SdkModelState>;
  setFilter: Reducer<SdkModelState>;
  setFilteredUnits: Reducer<SdkModelState>;
};

export const getUnitFilter = (modelState: SdkModelState) => {
  const { filter } = modelState;
  const { state, displayRent, bedroomCount, bathroomCount, availableNow } = filter;

  return (unit: Unit) => {
    const unitBathroomCount = getBathroomCount(unit);
    const unitState = unit.property.address.state;

    const matchState = state.value ? unitState === state.value : true;
    const matchDisplayRent =
      (displayRent.min ? unit.listedRent && unit.listedRent >= Number(displayRent.min) : true) &&
      (displayRent.max ? unit.listedRent && unit.listedRent <= Number(displayRent.max) : true);
    const matchBedroomCount =
      (bedroomCount.min ? unit.bedroomCount >= Number(bedroomCount.min) : true) &&
      (bedroomCount.max ? unit.bedroomCount <= Number(bedroomCount.max) : true);
    const matchBathroomCount =
      (bathroomCount.min ? unitBathroomCount >= Number(bathroomCount.min) : true) &&
      (bathroomCount.max ? unitBathroomCount <= Number(bathroomCount.max) : true);
    const matchAvailableNow = availableNow ? unit.listingStatus === ListingStatus.ACTIVE : true;

    return (
      matchState && matchDisplayRent && matchBedroomCount && matchBathroomCount && matchAvailableNow
    );
  };
};

const initialFilter = {
  state: {
    displayName: '',
    value: '',
  },
  displayRent: {
    min: '',
    max: '',
  },
  bedroomCount: {
    min: '',
    max: '',
  },
  bathroomCount: {
    min: '',
  },
  availableNow: false,
};

const model: Model<SdkModelState, Effects, Reducers> = {
  namespace: 'sdk',

  state: {
    config: {},
    blankPage: true,
    units: [],
    visibleUnits: [],
    mapData: {},
    stateEnums: [],
    states: [],
    filter: initialFilter,
    filteredUnits: [],
  },

  effects: {
    *fetchUnits({ payload = {} }, { call, put }) {
      // @ts-ignore
      const response = yield call(UnitService.search, payload);

      yield put({
        type: 'setUnits',
        payload: response,
      });

      yield put({ type: 'setStates' });
      yield put({ type: 'search' });
      yield put({ type: 'sortBy', payload: { key: 'listedRent', direction: 'ASC' } });
    },
    *updateMapData({ payload }, { put }) {
      yield put({ type: 'setMapData', payload });
      yield put({ type: 'search' });
    },
    *redirect(_, { select }) {
      const redirect: any = yield select(
        (state: ConnectState) => state.sdk.config.redirectSearchTo,
      );
      const filter = (yield select(
        (state: ConnectState) => state.sdk.filter,
      )) as typeof initialFilter;

      const params = pickBy(
        {
          minDisplayRent: filter.displayRent.min,
          maxDisplayRent: filter.displayRent.max,
          minBedroomCount: filter.bedroomCount.min,
          maxBedroomCount: filter.bedroomCount.max,
          minBathroomCount: filter.bathroomCount.min,
          availableNow: filter.availableNow,
          state: filter.state?.value,
        },
        (param) => param,
      );
      window.location.href = `${redirect}#/?${queryString.stringify(params)}`;
    },
    *filter({ payload }, { put, select }) {
      // @ts-ignore
      let filter: any = yield select((state: ConnectState) => state.sdk.filter);
      filter = { ...filter };

      Object.keys(payload).forEach((key) => {
        filter[key] = payload[key];
      });

      filter = pickBy(filter, (value) => value);

      const query = pickBy(
        {
          minDisplayRent: filter.displayRent.min,
          maxDisplayRent: filter.displayRent.max,
          minBedroomCount: filter.bedroomCount.min,
          minBathroomCount: filter.bathroomCount.min,
          availableNow: filter.availableNow,
          state: filter.state?.value,
        },
        (param) => param,
      );

      getHistory().push({ query } as any);
      yield put({ type: 'setFilter', payload: filter });
      yield put({ type: 'search' });
    },
    *search(_, { put }) {
      yield put({ type: 'setFilteredUnits' });
      yield put({ type: 'setVisibleUnits' });
    },
  },

  reducers: {
    setUnits(state, action) {
      return {
        ...state,
        blankPage: false,
        units: action.payload,
      };
    },
    sortBy(state, { payload: { key, direction } }) {
      const { visibleUnits } = state;
      const dir = direction === 'ASC' ? 1 : -1;
      const isByBathroom = key === 'bathroomCount';
      const sorted = [...visibleUnits].sort(
        isByBathroom
          ? (a, b) =>
              dir *
              collator.compare((getBathroomCount(a) ?? 0) as any, (getBathroomCount(b) ?? 0) as any)
          : (a, b) => dir * collator.compare(get(a, key) ?? 0, get(b, key) ?? 0),
      );

      return {
        ...state,
        visibleUnits: sorted,
      };
    },
    setActivePlace(state, { payload }) {
      return {
        ...state,
        activePlace: payload,
      };
    },
    setConfig(state, { payload }) {
      return {
        ...state,
        config: payload,
      };
    },
    setVisibleUnits(state) {
      const { filteredUnits, mapData } = state;
      const visibleUnits = filteredUnits.filter((unit) => {
        if (!mapData?.bounds) {
          return true;
        }

        return (
          !!unit.property.address.location &&
          mapData?.bounds?.contains({
            lat: unit.property.address.location?.latitude,
            lng: unit.property.address.location?.longitude,
          })
        );
      });

      return {
        ...state,
        visibleUnits,
      };
    },
    setMapData(state, { payload }) {
      return {
        ...state,
        mapData: payload,
      };
    },
    setStateEnums(state, { payload }) {
      return {
        ...state,
        stateEnums: payload,
      };
    },
    setStates(state) {
      const { units, stateEnums } = state;

      const stateKeys = [...new Set(units.map((unit) => unit.property.address.state))];
      const states = stateKeys
        .map((key) => stateEnums.find((stateEnum) => stateEnum.value === key)!)
        .sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

      return {
        ...state,
        states,
      };
    },
    setFilter(state, { payload = {} }) {
      return {
        ...state,
        filter: payload,
      };
    },
    setFilteredUnits(modelState) {
      const { units } = modelState;

      const filteredUnits = units.filter(getUnitFilter(modelState));

      return {
        ...modelState,
        filteredUnits,
      };
    },
  },
};

export default model;
